import { graphql, PageProps } from 'gatsby'

import Layout from '../../layouts'
import ShopSection from '../../sections/ShopSection'

const Shop = ({ data }: PageProps<Queries.ShopQuery>) => {
  return (
    <Layout
      smallNavBar
      seo={{
        title: data.strapiShop?.seo?.title,
        description: data.strapiShop?.seo?.description
      }}
    >
      <ShopSection data={data} />
    </Layout>
  )
}

export default Shop

export const query = graphql`
  query Shop {
    allStrapiProduct {
      nodes {
        id
        slug
        title
        description
        position
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 200, formats: [AUTO, WEBP])
            }
          }
          alternativeText
        }
        options {
          priceCents
          optionName
          displayName
        }
      }
    }
    strapiShop(seo: {}) {
      description {
        data {
          description
        }
      }
      seo {
        description
        title
      }
    }
  }
`
