import { IGatsbyImageData } from 'gatsby-plugin-image'
import { FC, ReactNode } from 'react'

import {
  Card,
  CardContent,
  CardIllustration,
  Desktop,
  Header,
  Mobile
} from './ResponsiveCard.styles'

type Props = {
  imageData: IGatsbyImageData
  imageAlt: string
  title: string
  content: ReactNode
}

const ResponsiveCard: FC<Props> = ({ imageData, imageAlt, title, content }) => {
  const image = imageData ? (
    <CardIllustration image={imageData} alt={imageAlt} />
  ) : null

  return (
    <Card>
      <Mobile>
        {image}
        <CardContent>
          <Header>{title}</Header>
          {content}
        </CardContent>
      </Mobile>
      <Desktop>
        <div style={{ padding: '35px 35px 0' }}>
          <Header>{title}</Header>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {image}
          <CardContent>{content}</CardContent>
        </div>
      </Desktop>
    </Card>
  )
}

export default ResponsiveCard
