import { IGatsbyImageData } from 'gatsby-plugin-image'
import { FC } from 'react'

import { ButtonLink } from '../../common/styles'
import ResponsiveCard from '../../components/Chip/ResponsiveCard'
import colors from '../../constants/colors'

import { OptionBlock, OrderBlock, Price, Text } from './ProductCard.styles'

type Props = {
  slug: string
  imageData: IGatsbyImageData
  imageAlt: string
  options: ({
    displayName: string
    optionName: string
    priceCents: number
  } | null)[]
  title: string
  description: string
}

const ProductCard: FC<Props> = ({
  slug,
  imageData,
  imageAlt,
  options,
  title,
  description
}) => {
  return (
    <ResponsiveCard
      imageData={imageData}
      imageAlt={imageAlt}
      title={title}
      content={
        <>
          <Text>{description}</Text>
          <OrderBlock>
            {options.map((option, index) => (
              <OptionBlock key={index}>
                {
                  // Capitalize first letter
                  option!.displayName[0].toUpperCase() +
                    option!.displayName.slice(1)
                }
                {' : '}
                <Price>{option!.priceCents / 100}€ TTC</Price>
              </OptionBlock>
            ))}
          </OrderBlock>
          <ButtonLink color={colors.pink} to={`/boutique/${slug}/`} large>
            Commander
          </ButtonLink>
        </>
      }
    />
  )
}

export default ProductCard
