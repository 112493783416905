import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { FlexCard, FlexIllustration } from '../../../common/styles'
import border from '../../../constants/border'
import breakpoint from '../../../constants/breakpoint'
import colors from '../../../constants/colors'
import spacing from '../../../constants/spacing'

export const Illustration = styled(GatsbyImage)`
  width: 200px;
  height: 200px;
  border-radius: ${border.LARGE_RADIUS};
  object-fit: cover;
  object-position: center center;
  margin-bottom: ${spacing.MEDIUM_PADDING};

  @media ${breakpoint.TABLET} {
    width: 400px;
    height: 400px;
  }
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &:not(:last-child) {
    padding-bottom: 21px;
    border-bottom: dotted 1px black;
    margin-bottom: 35px;
  }
`

export const Header = styled.h2`
  font-family: DM Serif Display;
  margin-bottom: 28px;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;

  @media ${breakpoint.TABLET} {
    margin-bottom: 0;
  }
`

export const Text = styled.p`
  line-height: 28px;
  margin-bottom: 35px;
`

export const OrderBlock = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 35px;
`

export const OptionBlock = styled.li`
  /* bullet style */
  list-style: square;
  &:not(:last-child) {
    margin-bottom: 7px;
  }
`

export const OptionSelect = styled.select`
  height: 30px;
  border: 1px solid ${colors.pink};
  border-radius: ${border.LARGE_RADIUS};
  margin-right: 10px;
  padding: 0 14px;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 200;
  line-height: 24px;
  color: ${colors.black};
  background-color: ${colors.white};

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: lightgray;
  }
`

export const Price = styled.span`
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
`

export const Card = styled(FlexCard)`
  width: 100%;
  flex-direction: column;

  @media ${breakpoint.TABLET} {
    width: 600px;
    flex-direction: row;
    max-height: 100%;
  }
`

export const CardIllustration = styled(FlexIllustration)`
  height: 100%;
  max-height: 200px;
  width: 100%;

  @media ${breakpoint.TABLET} {
    height: 200px;
    width: 200px;
    border-radius: 100%;
    margin-left: ${spacing.MAIN_PADDING};
    object-fit: cover;
    object-position: center center;
  }
`

export const Desktop = styled.div`
  display: none;

  @media ${breakpoint.TABLET} {
    display: block;
  }
`

export const Mobile = styled.div`
  display: block;

  @media ${breakpoint.TABLET} {
    display: none;
  }
`

export const CardContent = styled.div`
  flex: 1;
  padding: ${spacing.MAIN_PADDING};
  display: flex;
  flex-direction: column;
  align-items: center;
`
