import { PageProps } from 'gatsby'
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { Intro, TitleLarge } from '../../common/styles'
import MarkdownWrapper from '../../components/MarkdownWrapper'

import ProductCard from './ProductCard'
import { Container } from './ShopSection.styles'

const Title = styled(TitleLarge)`
  margin-top: 28px;
  padding-right: 7px;
  padding-left: 7px;
`

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  padding: 35px 21px;
  max-width: 100%;
`

const Markdown = styled(MarkdownWrapper)`
  h2 {
    margin-top: 0;
    padding-top: 0;
    border: none;
  }
`

const ShopSection = ({
  data: {
    allStrapiProduct: { nodes },
    strapiShop
  }
}: Pick<PageProps<Queries.ShopQuery>, 'data'>) => (
  <Container>
    <Title>Boutique: carte cadeaux, cours et prestations de maquillage</Title>
    <Intro>
      <Markdown>{strapiShop!.description?.data?.description!}</Markdown>
    </Intro>
    <CardsContainer>
      {[...nodes]
        .sort((a, b) => Number(a.position) - Number(b.position))
        .map(({ title, description, options, image, slug }, index) => {
          const imageData = getImage(
            image?.localFile as unknown as IGatsbyImageData
          )

          return (
            <ProductCard
              key={index}
              slug={slug!}
              imageData={imageData!}
              imageAlt={image?.alternativeText!}
              options={
                options as {
                  optionName: string
                  displayName: string
                  priceCents: number
                }[]
              }
              title={title!}
              description={description!}
            />
          )
        })}
    </CardsContainer>
  </Container>
)

export default ShopSection
